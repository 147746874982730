<template>
    <section class="reset">
        <div class="form__container">
            <div class="form__left">
                <div class="form__title">
                    {{ $t('reset.title1') }}
                </div>

                <form class="form__box">
                    <div class="form__group">
                        <label class="form__name form__name-email" for="form-email">{{ $t('reset.text1') }}</label>
                        <input class="form__input" v-validate="'required'" type="email" :placeholder="$t('reset.text2')"
                            id="form-email" name="email" v-model="email">
                    </div>

                    <button class="form__button" type="button" @click="resetPassword">
                        <span>{{ $t('reset.btn1') }}</span>
                    </button>
                </form>

                <div class="form__sub">
                    <div class="form__sub-text">{{ $t('reset.text3') }}</div>
                    <a class="form__sub-link" href="/signin">{{ $t('reset.link1') }}</a>
                </div>
            </div>

            <div class="form__right">
                <img src="../assets/img/space_three.png" alt="">
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import CONFIG from '../config/settings'
const API_URL = CONFIG.APIURL

export default {
    data() {
        return {
            email: null
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        }
    },
    created() {
        if (this.loggedIn) {
            window.location.replace(CONFIG.BASEURL + '/trading')
        }
    },
    methods: {
        resetPassword() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    return axios.post(API_URL + 'auth/reset/password', {
                        email: this.email
                    }).then(response => {
                        this.email = null
                        this.$toast.success(response.data.message)
                    }).catch(err => {
                        this.$toast.error(err.response.data.message)
                    })
                }
                else {
                    this.$toast.warning(this.errors.first('email'))
                }
            })
        }
    }
}
</script>